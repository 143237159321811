import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import About from 'components/About';

const AboutPage: React.FC = () => {
  const metaDesc = 'Who is Dattatraya Dongare aka Datt Dongare? Know brief about his professional summary and what he is doing currently. Also what his interests are.';
  return (
    <Layout>
      <SEO title="Know more about me" description={metaDesc} />
      <About />
      <hr />
    </Layout>
  );
};

export default AboutPage;
